import logo from './images/knuckle-heads.png';
import './AppUnderConstruct.css';
import {Route, Routes} from "react-router-dom";
import {NotFound} from "./pages/HttpStatus/NotFound";

function App() {
    return (
        <>
            <Routes>
                <Route path='/' element=
                    {
                        <div className="App">
                        <header className="App-header">
                            <img src={logo} className="App-logo" alt="logo" />
                        </header>
                        </div>
                    }
                />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </>
    );
}

export default App;
