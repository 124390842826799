import { Typography } from "@mui/material";
import { useEffect } from "react";

export const NotFound = () =>  {
    useEffect(() => { document.title = "maaldo.com | Not Found" });

    return (
        <>
        <Typography variant="h4" gutterBottom>Not Found</Typography>
        </>
    )
}